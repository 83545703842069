import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        loadComponent: () => import('./landing-page/landing-page.component').then((m) => m.LandingPageComponent),
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: '',
    }
];
